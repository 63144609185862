@import "@ribbon.org/components/src/styles/variables.scss";

#donation-embed {
	color: $black;
	width: 100% !important;
	max-width: 100vw !important;
	max-width: 480px !important;
	min-height: 350px !important;
	@media screen and (max-width: 480px) {
		max-width: 100vw !important;
	}

	.dialog-sheet {
		height: 100vh !important;
		width: 100vw !important;
		background-color: white;
	}
	.step-body {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	.background-fade {
		background: linear-gradient(180deg, #1cd4e3 0%, #3770fe 100%);
	}
	.most-popular {
		font-weight: 400;
		font-family: 'Yellowtail', cursive;
		color: $accent !important;
		font-size: 1.25rem !important;
		margin-bottom: 0px !important;
	}
	.row-center {
		justify-content: center !important;
		align-items: center !important;
		display: flex !important;
	}
	.flexible-content .spacer {
		background: inherit !important;
		width: unset !important;
		height: unset !important;
	}
	p {
		font-family: inherit !important;
	}

	::v-deep .v-btn:not(.v-btn--round).v-size--default {
		min-width: 64px !important;
	}
}

.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 100% !important;
}

#donation-embed::v-deep .v-toolbar__content {
	height: 50px !important;
}

#donation-embed::v-deep .roundup-button {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-btn:before {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-btn--is-elevated:after {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-btn:hover:before {
	border-radius: 4px !important;
}

#donation-embed::v-deep .roundup-button:hover:enabled::after {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-application--wrap {
	min-height: 350px !important;
	padding-bottom: 2em !important;
}
