<template>
	<div data-vuetify>
		<v-app id="donation-embed" data-app>
			<v-dialog
				v-model="showDialog"
				v-if="dialog"
				:fullscreen="isMobile"
				max-width="480px"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						:color="form.primary_color"
						dark
						v-bind="attrs"
						v-on="on"
						max-width="80%"
					>
						Donate Now
					</v-btn>
				</template>
				<v-card>
					<DonationForm
						:overrides="overrides"
						:stripeKey="stripeKey"
						:form="form"
						:onSubmit="onSubmit"
						v-if="form.uuid"
					></DonationForm>
					<v-skeleton-loader
						v-else
						type="table-heading, list-item-two-line, image, table-tfoot"
					></v-skeleton-loader>
				</v-card>
			</v-dialog>
			<div v-else>
				<DonationForm
					:overrides="overrides"
					:stripeKey="stripeKey"
					:form="form"
					:onSubmit="onSubmit"
					v-if="form.uuid"
				></DonationForm>
				<v-skeleton-loader
					v-else
					type="table-heading, list-item-two-line, image, table-tfoot"
				></v-skeleton-loader>
			</div>
		</v-app>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { getUrlParams, get } from '@/utilities'

export default {
	name: 'RibbonDonationEmbed',
	data() {
		return {
			dialog: false,
			showDialog: false,
			overrides: {}
		}
	},
	computed: {
		...mapGetters(['form']),
		isMobile() {
			return screen.width <= 760
		},
		stripeKey() {
			return process.env.VUE_APP_STRIPE_KEY
		}
	},
	methods: {
		...mapActions(['getForm', 'donate', 'donateRecurring']),
		onSubmit(body) {
			const data = {
				...body,
				...body.inHonorOf,
				in_honor_of: body.inHonorOf ? body.inHonorOf.honoree_full_name : '',
				creation_json: JSON.stringify({
					url: window.location.href
				}),
				form_uuid: this.form.uuid,
				method: 'Card'
			}
			if (body.is_recurring) {
				return this.donateRecurring(data)
			} else {
				return this.donate(data)
			}
		}
	},
	mounted() {
		const el = document.querySelector('ribbon-donation-embed')
		this.dialog = el.getAttribute('dialog') === 'true'
		const form_uuid = el.getAttribute('form_uuid')
		this.getForm(form_uuid).then(() => {
			this.amount = this.form.donation_option_2
		})

		var styleEl = document.createElement('style')
		styleEl.innerHTML =
			'.container {\n\twidth: 100%;\n\tpadding: 0px !important;\n\tmargin-right: auto;\n\tmargin-left: auto;\n}\n\n@media (min-width: 960px) {\n\t.container {\n\t\tmax-width: 100% !important;\n\t}\n}\n\n@media (min-width: 1264px) {\n\t.container {\n\t\tmax-width: 100% !important;\n\t}\n}\n\n@media (min-width: 1904px) {\n\t.container {\n\t\tmax-width: 100% !important;\n\t}\n}'
		document.head.append(styleEl)
	}
}
</script>

<style lang="scss" scoped>
#donation-embed {
	color: $black;
	width: 100% !important;
	max-width: 100vw !important;
	max-width: 480px !important;
	min-height: 350px !important;
	@media screen and (max-width: 480px) {
		max-width: 100vw !important;
	}

	.dialog-sheet {
		height: 100vh !important;
		width: 100vw !important;
		background-color: white;
	}
	.step-body {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	.background-fade {
		background: linear-gradient(180deg, #1cd4e3 0%, #3770fe 100%);
	}
	.most-popular {
		font-weight: 400;
		font-family: 'Yellowtail', cursive;
		color: $accent !important;
		font-size: 1.25rem !important;
		margin-bottom: 0px !important;
	}
	.row-center {
		justify-content: center !important;
		align-items: center !important;
		display: flex !important;
	}
	.flexible-content .spacer {
		background: inherit !important;
		width: unset !important;
		height: unset !important;
	}
	p {
		font-family: inherit !important;
	}

	::v-deep .v-btn:not(.v-btn--round).v-size--default {
		min-width: 64px !important;
	}
}

.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 100% !important;
}

#donation-embed::v-deep .v-toolbar__content {
	height: 50px !important;
}

#donation-embed::v-deep .roundup-button {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-btn:before {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-btn--is-elevated:after {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-btn:hover:before {
	border-radius: 4px !important;
}

#donation-embed::v-deep .roundup-button:hover:enabled::after {
	border-radius: 4px !important;
}

#donation-embed::v-deep .v-application--wrap {
	min-height: 350px !important;
	padding-bottom: 2em !important;
}
</style>

<style>
.container {
	width: 100%;
	padding: 0px !important;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 960px) {
	.container {
		max-width: 100% !important;
	}
}

@media (min-width: 1264px) {
	.container {
		max-width: 100% !important;
	}
}

@media (min-width: 1904px) {
	.container {
		max-width: 100% !important;
	}
}
</style>
